import React from 'react'
import Portal from './Portal'
import styled from 'styled-components'

const Modal = props => {
  if (!props.open) return null
  return (
    <Portal>
      <Wrapper>
        <Content>{props.children}</Content>
      </Wrapper>
    </Portal>
  )
}

export default Modal

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(170, 170, 170, 0.55);
`

const Content = styled.div`
  min-width: 300px;
  background-color: #fff;
  border: 0.5px solid black;
  box-shadow: 1.3px 1.3px 1.9px 0 rgba(26, 24, 24, 0.25);
  border-radius: 3px;
  padding: 15px;
  transform: translateY(-20px);
`
