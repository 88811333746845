import styled, { css } from 'styled-components'
import { createStatic } from 'styled-components-breakpoint'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import DropdownList from 'react-widgets/lib/DropdownList'
import { Button, Input, primaryFont, Text } from '../../GlobalStyles'
import { setHours, startOfHour } from 'date-fns'
import dropdownIcon from '../../images/dropdown.png'
import dateIcon from '../../images/date-icon.png'
import timeIcon from '../../images/time-icon.png'
import personIcon from '../../images/persons-icon.png'

const breakpoint = createStatic({ mobileInputs: 530 })

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0;
`

export const BookInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  ${breakpoint.mobileInputs`
   flex-direction: row;
  `};
  margin-bottom: 25px;
  & > * {
    flex-grow: 1;
    margin-bottom: 0;
    min-width: 150px;
    width: 100%;
    ${breakpoint.mobileInputs`
      width: auto;
    `};
  }
`

export const IconInputWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const DateWrapper = styled(IconInputWrapper)`
  margin-top: 25px;
  ${breakpoint.mobileInputs`
    margin-top: 0;
  `};
`

export const TimeWrapper = styled(DateWrapper)``

export const DateIcon = styled.img.attrs({
  src: dateIcon
})`
  width: 25px;
  margin: 0 2px 0 0;
`

export const TimeIcon = styled.img.attrs({
  src: timeIcon
})`
  width: 25px;
  margin: 0 2px 0 0;
`

export const PersonIcon = styled.img.attrs({
  src: personIcon
})`
  width: 25px;
  margin: 0 2px 0 0;
`

const pickerCls = 'pickerCls'
const commonPickerCss = css`
  & .${pickerCls} {
    ${primaryFont};
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #000;
    box-shadow: 2px 3px 4px 0 rgba(26, 24, 24, 0.25);
    padding-left: 5px;
    height: 30px;
    font-size: 15px;
    & .rw-input {
      padding: 0;
    }
    & .rw-input[readonly] {
      cursor: pointer;
    }
    & .rw-select {
      position: relative;
      border-left-width: 0;
    }
  }
  &.rw-state-focus {
    & .${pickerCls} {
      outline: none;
      border-color: black !important;
      box-shadow: 0 0 2px 2px rgba(26, 24, 24, 0.55) !important;
    }
  }
`

export const DropdownIcon = styled.img.attrs({
  src: dropdownIcon
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const PersonSelect = styled(DropdownList).attrs({
  containerClassName: pickerCls
})`
  ${commonPickerCss};
  flex-grow: 1;
  ${breakpoint.mobileInputs`
    margin-right: 10px;
  `};
  .rw-list-option {
    font-size: 15px;
    margin-bottom: 0;
  }
`

export const DatePicker = styled(DateTimePicker).attrs({
  time: false,
  containerClassName: pickerCls,
  inputProps: {
    readOnly: true
  }
})`
  ${commonPickerCss};
  ${breakpoint.mobileInputs`
    margin-right: 10px;
  `};
  flex-grow: 1;
  .rw-calendar-header,
  .rw-calendar-head,
  .rw-calendar-body,
  .rw-calendar-footer {
    font-size: 15px;
  }
  .rw-calendar-grid {
    margin-bottom: 5px;
  }
`

export const TimePicker = styled(DateTimePicker).attrs({
  date: false,
  containerClassName: pickerCls,
  min: startOfHour(setHours(new Date(), 12)),
  max: startOfHour(setHours(new Date(), 23)),
  inputProps: {
    readOnly: true
  }
})`
  ${commonPickerCss};
  flex-grow: 1;
  .rw-list-option {
    font-size: 15px;
    margin-bottom: 0;
  }
`

export const Textarea = styled(Input).attrs({
  as: 'textarea',
  rows: '4'
})`
  height: auto;
`

export const FormNote = styled(Text)`
  font-size: 18px;
`

export const SubmitButton = styled(Button)`
  margin: 30px auto 50px;
`
