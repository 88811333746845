import React from 'react'
import Layout from '../layout'
import Book from '../screens/Book'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Book />
  </Layout>
)

export default IndexPage
