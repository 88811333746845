import React, { useState, useEffect } from 'react'
import { useTranslation } from '../../i18n'
import SEO from '../../components/seo'
import { Input, PageTitle } from '../../GlobalStyles'
import { addMonths, format, parse } from 'date-fns'
import { encode, validateEmail } from '../../utils'
import * as R from 'ramda'
import {
  BookInfo,
  DateIcon,
  DatePicker,
  DateWrapper,
  DropdownIcon,
  Form,
  FormNote,
  IconInputWrapper,
  PersonIcon,
  PersonSelect,
  SubmitButton,
  Textarea,
  TimeIcon,
  TimePicker,
  TimeWrapper
} from './css'

import 'react-widgets/dist/css/react-widgets.css'
import dateFnsLocalizer from 'react-widgets-date-fns'
import InfoModal from './InfoModal'
dateFnsLocalizer()

const validateForm = (form, t) => {
  const { name, email, phoneNumber, persons, date, time } = form
  if (!name || !phoneNumber || !persons || !date || !time || !email)
    return t('error_forgot') + t('error_required_fields')
  if (!validateEmail(email)) return t('error_title') + t('error_email')
}

const sendForm = form => {
  return fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode(form)
  })
}

const PERSON_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 25]
const FORM_NAME = 'book'
const INIT_FORM = { 'form-name': FORM_NAME }

const Book = () => {
  const { t } = useTranslation()
  const [isDateOpen, toggleDateOpen] = useState(false)
  const [isTimeOpen, toggleTimeOpen] = useState(false)
  const [form, setForm] = useState(INIT_FORM)
  const [loading, setLoading] = useState(false)
  const [modalOpen, toggleModal] = useState(false)
  const [errors, setErrors] = useState()

  const onChange = formPart => setForm({ ...form, ...formPart })
  const onFieldChange = ev => onChange({ [ev.target.name]: ev.target.value })

  const clearForm = () => setForm(INIT_FORM)
  const submitForm = () => setLoading(true)
  useEffect(() => {
    const postForm = async formData => {
      try {
        setErrors()
        const error = validateForm(formData, t)
        if (!error) {
          await sendForm(formData)
          clearForm()
        } else {
          setErrors(error)
        }
        toggleModal(true)
      } catch (e) {
        // show error message
      }
      setLoading(false)
    }
    if (loading) postForm(form)
    return () => {}
  }, [loading])

  return (
    <>
      <SEO
        title="Book"
        keywords={[`nikos gallop`, `book`, 'table reservation']}
      />
      <PageTitle>{t('book_title')}</PageTitle>
      <Form name={FORM_NAME} netlify data-netlify={true}>
        <input type="hidden" name="form-name" value={FORM_NAME} />
        <Input
          type="text"
          name="name"
          placeholder={t('book_input_name')}
          onChange={onFieldChange}
          value={R.propOr('', 'name', form)}
        />
        <Input
          type="email"
          name="email"
          placeholder={t('book_input_email')}
          onChange={onFieldChange}
          value={R.propOr('', 'email', form)}
        />
        <Input
          type="text"
          name="phoneNumber"
          placeholder={t('book_input_phone')}
          onChange={onFieldChange}
          value={R.propOr('', 'phoneNumber', form)}
        />
        <BookInfo>
          <IconInputWrapper>
            <PersonIcon />
            <PersonSelect
              placeholder={t('book_input_persons')}
              name="persons"
              selectIcon={<DropdownIcon />}
              data={[...PERSON_OPTIONS]}
              onChange={num => {
                onChange({ persons: num })
              }}
              value={R.prop('persons', form)}
            />
          </IconInputWrapper>
          <DateWrapper>
            <DateIcon />
            <DatePicker
              placeholder={t('book_input_date')}
              name="date"
              dateIcon={<DropdownIcon />}
              format={'d/M/yyyy'}
              min={new Date()}
              max={addMonths(new Date(), 3)}
              open={isDateOpen}
              onClick={isDateOpen ? null : () => toggleDateOpen('date')}
              onToggle={state => toggleDateOpen(state)}
              onChange={date => {
                onChange({
                  date: format(date, 'd/M/yyyy')
                })
                toggleDateOpen(false)
              }}
              value={form.date && parse(form.date, 'd/M/yyyy', new Date())}
            />
          </DateWrapper>
          <TimeWrapper>
            <TimeIcon />
            <TimePicker
              placeholder={t('book_input_time')}
              name="time"
              timeIcon={<DropdownIcon />}
              format={'HH:mm a'}
              timeFormat={'HH:mm a'}
              open={isTimeOpen}
              onClick={isTimeOpen ? null : () => toggleTimeOpen('time')}
              onToggle={state => toggleTimeOpen(state)}
              onChange={date => {
                onChange({
                  time: format(date, 'HH:mm')
                })
                toggleTimeOpen(false)
              }}
              value={form.time && parse(form.time, 'HH:mm', new Date())}
            />
          </TimeWrapper>
        </BookInfo>
        <Textarea
          name="notes"
          placeholder={t('book_input_notes')}
          onChange={onFieldChange}
          value={R.propOr('', 'notes', form)}
        />
      </Form>
      <FormNote>{t('book_form_note')}</FormNote>
      <SubmitButton onClick={submitForm} loading={loading} disabled={loading}>
        {t('book_submit_button')}
      </SubmitButton>
      <InfoModal
        onClose={() => toggleModal(false)}
        open={modalOpen}
        error={errors}
      />
    </>
  )
}

export default Book
