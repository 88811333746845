import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

const Portal = ({ children }) => {
  const [el] = useState(document.createElement('div'))
  useEffect(() => {
    const modalRoot = document.getElementById('modal-root')
    modalRoot.appendChild(el)
    modalRoot.style.display = 'block'
    return () => {
      modalRoot.style.display = 'none'
      modalRoot.removeChild(el)
    }
  }, [])

  return ReactDOM.createPortal(children, el)
}

export default Portal
